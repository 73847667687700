// ネイティブアプリ向けの戻るボタンを追加するjs

import DocumentReady from 'document-ready-promise'

DocumentReady().then(() => {
  // アプリではない場合はスキップ
  if (!window?.webkit?.messageHandlers?.native?.postMessage) return

  console.log('native app append.js')

  const style = document.createElement('style')
  style.innerText = `
    .native-app-back-btn {
      padding-top: env(safe-area-inset-top);
      margin-top: 1rem;
      padding: 0 0.5rem;
      color: black;
      position: relative;
      top: 0;
      left: 0;
      padding: 2rem;
      justify-content: start;
      align-items: center;
      display: flex;
    }

    .native-app-back-btn a, .native-app-back-btn a:link,
    .native-app-back-btn a:visited, .native-app-back-btn a:active {
      color: black;
      font-size: 3rem;
      cursor: pointer;
      display: flex;
      align-items: center;
      text-decoration: none;
    }

    .native-app-back-btn svg {
      font-size: 3rem;
    }

    .native-app-back-btn span {
      font-size: 1rem;
      padding-left: 0.5rem;
    }
  `
  document.head.appendChild(style)


  const div = document.createElement('div')
  div.className = 'native-app-back-btn'

  const a = document.createElement('a')
  a.href = '/vr_tour_app'
  a.innerHTML = '<svg data-v-4ed88629="" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-left" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-chevron-left fa-w-10"><path data-v-4ed88629="" fill="currentColor" d="M34.52 239.03L228.87 44.69c9.37-9.37 24.57-9.37 33.94 0l22.67 22.67c9.36 9.36 9.37 24.52.04 33.9L131.49 256l154.02 154.75c9.34 9.38 9.32 24.54-.04 33.9l-22.67 22.67c-9.37 9.37-24.57 9.37-33.94 0L34.52 272.97c-9.37-9.37-9.37-24.57 0-33.94z" class=""></path></svg> <span>戻る</span>'
  div.appendChild(a)
  document.body.prepend(div)
})
